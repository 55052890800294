<template>
    <StepTemplate
        :isLong="true"
        modifier="city"
    >
        <template v-slot:headerTitle>
            Авторизация
        </template>
        <ActionForm
            @submit="submit"
            actionName="Войти"
        >
            <Phone
                title="Телефон"
                v-model="form.phone"
                :error="formErrors.phone"
                autofocus
            />
        </ActionForm>
    </StepTemplate>
</template>

<script>
import Phone from '@/components/form/field/Phone'
import ActionForm from '@/templates/action-form/ActionForm'

import validation from '@/mixins/validation'
import Validation from '@/ext/validation/Validation'

import auth from '@/api/auth'

export default {
    name: 'Login',
    mixins: [validation],
    data() {
        return {
            form: {
                phone: ''
            },
            formRules: {
                phone: [Validation.REQUIRED, Validation.PHONE]
            }
        }
    },
    methods: {
        async submit() {
            this.validate()

            if (!this.isValid)
                return

            const response = await auth(this.form)

            if (typeof response.result_phone === 'boolean' && !response.result_phone) {
                this.validate(false)
                this.formErrors.phone = 'Данный номер не зарегистрирован'

                return
            }

            this.$router.push({
                name: 'LoginCode',
                params: {
                    data: this.form
                }
            })
        },
        back() {
            this.$router.push({
                name: 'Landing'
            })
        }
    },
    components: {
        ActionForm,
        Phone,
    }
}
</script>